body {
    margin: 0;
    font-family: "Noto Sans JP", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f7f3e9;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
/* CSS Document */
html,
body,
header,
footer,
h1,
h2,
h3,
h4,
h5,
h6,
section,
ul,
li,
dl,
dt,
dd,
p,
div,
a,
table,
tr,
td,
th,
input,
textarea,
select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html,
body,
#root {
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    font-family: "Noto Sans JP", sans-serif;
    box-sizing: border-box;
    letter-spacing: 0.5px;
    > div > footer {
        position: sticky;
        top: 100vh;
    }
}
ul {
    list-style: none;
}
a {
    text-decoration: none;
    color: #000;
    transition-duration: 0.3s;
}
img {
    max-width: 100%;
}
.pc {
    display: none;
    @media screen and (min-width: 768px) {
        display: block;
    }
}
.sp {
    display: block;
    @media screen and (min-width: 768px) {
        display: none;
    }
}
.anchor {
    margin-top: -80px;
    padding-top: 80px;
}
.nowrap {
    white-space: nowrap;
}

img {
    max-width: 100%;
}
main {
    padding-top: 70px;
    @media screen and (min-width: 768px) {
        padding-top: 100px;
    }
}
.container {
    max-width: 1200px;
    margin: 0 auto;
}
.cloud {
    position: relative;
    height: 100px;
    width: 100%;
    padding-bottom: 50px;
    overflow: hidden;
    @media screen and (min-width: 768px) {
        height: 150px;
        margin-bottom: 70px;
    }
    .cloud1 {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        height: 60%;
    }
    .cloud2 {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 40%;
    }
}
.cloudparts {
    animation: cloudmove 5s ease-in-out 0s infinite;
}
.topservice {
    margin: 50px auto;
    padding: 0 15px;
    &-flex {
        display: flex;
        flex-direction: column;
        gap: 30px;
        @media screen and (min-width: 1000px) {
            flex-direction: row;
            align-items: center;
            transform:translate(0,-100px);
        }
        &-col {
            display: flex;
            flex-direction: column;
            padding-top:30px;
        }
    }
    &-right {
        text-align: right;
    }
    &-btn {
        display: inline-flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        margin-top: 30px;
        border-bottom: 2px solid #4e413b;
        position: relative;
        padding: 5px;
        &:after {
            content: "";
            position: absolute;
            background: #e9c93f;
            width: 30px;
            height: 2px;
            bottom: -2px;
            left: 0;
        }
    }
    &-support {
        &-col {
            width: 100%;
            padding-bottom: 100px;
            position: relative;
            @media screen and (min-width: 768px) {
                padding-bottom: 70px;
            }
            @media screen and (min-width: 1000px) {
                min-height: 600px;
                width: 40%;
            }
            &.figure {
                @media screen and (min-width: 1000px) {
                    min-width: 600px;
                }
            }
            .blue {
                display: none;
                @media screen and (min-width: 1000px) {
                    border: 3px solid #46bee6;
                    border-radius: 50%;
                    width: 70px;
                    height: 70px;
                    margin: 0 auto;
                    z-index: 3;
                    display: block;
                    position: absolute;
                    top: calc(50%);
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                &-left,
                &-right {
                    width: 55px;
                    height: 55px;
                    background: #46bee6;
                    color: #fff;
                    font-size: 12px;
                    font-weight: bold;
                    border-radius: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    @media screen and (min-width: 1000px) {
                        font-size: 16px;
                        width: 80px;
                        height: 80px;
                    }
                }
                &-left {
                    transform: translate(-50%, -25%);
                    top: 0;
                    left: 0;
                }
                &-right {
                    bottom: 0;
                    right: 0;
                    transform: translate(50%, 25%);
                }
            }
            .circle {
                display: flex;
                flex-direction: column;
                gap: 20px;
                @media screen and (min-width: 1000px) {
                    gap: 0;
                    padding: 0;
                    display: block;
                    position: absolute;
                    width: 360px;
                    height: 360px;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    top: 0;
                    width: 470px;
                    height: 470px;
                }
                @media screen and (min-width: 1000px) {
                    position: absolute;
                    width: 600px;
                    height: 600px;
                    z-index: 2;
                    top: 0;
                    left: auto;
                    transform: none;
                }
                &-title {
                    border-bottom: 1px solid #837e7c;
                    text-align: center;
                    padding-bottom: 10px;
                    width: 100%;
                    margin-bottom: 10px;
                    font-size: 16px;
                    border-left: 10px solid #46bee6;
                    padding-top: 10px;
                    position: relative;
                    @media screen and (max-width: 1000px) {
                        &:before {
                            content: "";
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            width: 20px;
                            height: 1px;
                            background: #fff;
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -1px;
                            right: 0;
                            width: 20px;
                            height: 1px;
                            background: #fff;
                        }
                    }
                    @media screen and (min-width: 1000px) {
                        font-size: 16px;
                        border-left: none;
                        padding-top: 0;
                    }
                    &-eng {
                        font-size: 12px;
                        display: block;
                    }
                }
                &-flex {
                    @media screen and (min-width: 1000px) {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: rgba(255, 255, 255, 0.8);
                        border-radius: 50%;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }
                }
                &-col {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    &:nth-child(2) {
                        display: flex;
                        @media screen and (min-width: 1000px) {
                            display: flex;
                        }
                    }
                    ul {
                        column-count: 2;
                        padding-left: 15px;
                        padding-right: 15px;
                        @media screen and(min-width:1000px) {
                            margin-right: auto;
                            margin-left:auto;
                            padding-left: 0;
                            padding-right: 0;
                            column-count: 1;
                            display: block;
                        }
                        li {
                            @media screen and(min-width:1000px) {
                                width: auto;
                            }
                        }
                    }
                    &.single {
                        ul {
                            column-count: 1;
                        }
                    }
                }
                &-top,
                &-left,
                &-right,
                &-bottom {
                    display: block;
                    background: #fff;
                    padding-bottom: 20px;
                    @media screen and (min-width: 1000px) {
                        padding-bottom: 0;
                        position: absolute;
                        margin: 0;
                        background: none;
                    }
                }
                &-top {
                    @media screen and (min-width: 1000px) {
                        left: 50%;
                        top: 0;
                        transform: translate(-50%, 0);
                        width: 280px;
                        height: 280px;
                    }
                }
                &-left {
                    @media screen and (min-width: 1000px) {
                        left: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                        width: 260px;
                        height: 260px;
                    }
                }
                &-right {
                    @media screen and (min-width: 1000px) {
                        right: 0;
                        top: 50%;
                        padding: 20px;
                        transform: translate(0, -50%);
                        width: 260px;
                        height: 260px;
                    }
                }
                &-bottom {
                    @media screen and (min-width: 1000px) {
                        left: 50%;
                        bottom: 0;
                        padding: 20px;
                        transform: translate(-50%, 0);
                        width: 250px;
                        height: 250px;
                    }
                }
                .marketing {
                    &-flex {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        gap: 20px;
                        padding-left:15px;
                        padding-right:15px;
                        @media screen and (min-width: 1000px) {
                            padding-left:0;
                            justify-content: center;
                            gap: 0;
                            padding-left:0px;
                            padding-right:0px;
                            width:auto;
                            }
                    }
                    &-col {
                        ul {
                            column-count: 1;
                            padding-left:0;
                            li {
                                width: 100%;
                            }
                        }
                        @media screen and (min-width: 1000px) {
                            width: 100%;
                        }
                    }
                }
            }
            .title {
                font-size: 11px;
                font-weight: bold;
            }
            .icon-text {
                font-size: 13px;
                letter-spacing: -1px;
                line-height:1.8;
                @media screen and (min-width:768px){
                    font-size:11px;
                    line-height:1.5;
                    white-space: nowrap;
                }
                &::before {
                    content: "・";
                }
            }
        }
    }
}
.content-footer {
    canvas {
        max-width: 100%;
    }
    div {
        max-width: 100%;
    }
}
@keyframes cloudmove {
    0% {
        transform: translateX(-50%);
    }

    50% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-50%);
    }
}
div {
    //transition: all 1s ease-out;
}
