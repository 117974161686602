div{
    box-sizing: border-box;
}
.submenu {
    max-width: 900px;
    margin: 0 auto;
    border-bottom: 1px solid #b3a8a1;
    &-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
        a {
            text-align: center;
            padding: 5px 5px;
            @media screen and (min-width: 768px) {
                min-width: 150px;
            }
        }
        .active {
            border-bottom: 1px solid #4e413b;
        }
    }
}
.works {
    padding: 0 15px;
    &-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        max-width: 900px;
        margin: 0 auto;
        &-col {
            width: calc((100% - 10px) / 2);
            @media screen and (min-width: 768px) {
                width: calc((100% - 20px) / 3);
            }
        }
        &-title {
            font-size: 12px;
            max-width: 900px;
            margin: 0 auto;
            margin-top: 1.5rem;
            height: 2rem;
            display: flex;
            align-items: center;
            @media screen and (min-width: 768px) {
                font-size: 14px;
            }
        }
    }
}
.detail {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    @media screen and (min-width: 768px) {
        flex-direction: row;
        gap: 50px;
    }
    &-img {
        width: 100%;
        background: #fff;
        @media screen and (min-width: 768px) {
            width: 55%;
            padding: 50px;
        }
    }
    &-text {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction:column;
        @media screen and (min-width: 768px) {
            width: 45%;
            align-items: flex-start;
        }
        &-area {
            background: rgba(255, 255, 255, 0.57);
            padding: 15px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            @media screen and (min-width: 768px) {
                max-width: 300px;
                padding: 30px 15px;
                /*height: 55vw;*/
                max-height: 670px;
            }
        }
        &-name {
            padding-top: 3rem;
        }
    }
    &-back{
        padding-top:15px;
        width:100%;
        max-width:300px;
        &-btn{
            text-align:center;
            padding:8px;
            color:#fff;
            background:#03ACCA;
            font-weight:bold;
            width:100%;
            border-radius:10px;
            display:block;
            transition: all 0.5s;
            &:hover{
                opacity:0.6;
            }
        }
    }
    &-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-top:15px;
        padding-left:15px;
        @media screen and (min-width: 768px) {
            padding:0;
        }
        &-text {
            font-weight: 900;
            font-size: 16px;
            @media screen and (min-width: 768px) {
                font-size: 22px;
            }
        }
    }
    &-slider {
        &-nav {
            @media screen and (min-width: 768px) {
                margin: 0 -10px;
            }
            &-btn {
                padding: 10px;
                img {
                    border: 1px solid #42342e;
                }
            }
        }
    }
}
