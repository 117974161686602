.title-flex {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 50px;
    @media screen and (min-width: 768px) {
        flex-direction: column;
    }
}
.leaflet-container,
.leaflet-title-container {
    width: 100%;
    height: 300px;
    margin: 20px 0;
    overflow: hidden;
}
.about {
    &-title {
        width: 75%;
    }
    &-vision,
    &-policy,
    &-company {
        background: #f0e9d6;
        &-flex {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 15px 50px;
            @media screen and (min-width: 768px) {
                flex-direction: row;
                padding: 0 50px 50px;
                justify-content: space-between;
            }
            &.rv {
                @media screen and (min-width: 768px) {
                    flex-direction: row-reverse;
                }
            }
        }
        &-col {
            width: 100%;
            padding-bottom: 70px;
            &.bg {
                background: linear-gradient(
                    to right,
                    rgba(255, 255, 255, 0.57) 0%,
                    rgba(255, 255, 255, 0.57) 60%,
                    rgba(255, 255, 255, 0) 60.5%,
                    rgba(255, 255, 255, 0) 100%
                );
            }
            &.img {
                padding: 30px 0;
                max-width: 220px;
                @media screen and (min-width: 768px) {
                    max-width: none;
                }
            }
            @media screen and (min-width: 768px) {
                width: 45%;
            }
        }
    }
    &-vision {
        .bg {
            @media screen and(max-width:767px) {
                background: transparent;
                position: relative;
                &:before {
                    content: "";
                    position:absolute;
                    top:0;
                    left:0;
                    width:5px;
                    height:50%;
                    background: #e9c93f;
                }
                .about-h4{
                &:before {
                    content: "";
                    background: transparent;
                }
            }
            }
            @media screen and (min-width: 768px) {
                margin-top: -30px;
            }
        }
    }
    &-policy {
        background: transparent;
    }
    &-company {
        &-flex {
            flex-direction: column;
        }
        &-col {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-table {
            margin: 0 auto;
        }
        &-tr {
            border-bottom: 1px solid #2d2521;
        }
        &-th,
        &-td {
            font-size: 14px;
            font-weight: normal;
            padding: 10px 15px;
            text-align: left;
        }
    }
    &-subtitle {
        padding-top: 30px;
        img {
            height: 100px;
            max-height: 85px;
            margin: 0 10px;
            @media screen and (min-width: 768px) {
                height: 23.4vw;
                margin: auto;
                max-height: 234px;
            }
        }
        &.rv {
            @media screen and (min-width: 768px) {
                text-align: right;
            }
        }
    }
    &-block02 {
        padding-bottom: 50px;
        &-flex {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            font-size: 21px;
            padding: 40px 15px;
            width: 90%;
            margin: 0 auto;
            background: rgba(255, 255, 255, 0.57);
            @media screen and (min-width: 768px) {
                font-size: 31px;
                width: 80%;
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 5px;
                height: 50%;
                background: #e9c93f;
            }
        }
        &-col {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        &-h4 {
            font-size: 16px;
            @media screen and (min-width: 768px) {
                font-size: 21px;
            }
        }
        &-table {
            width: 100%;
            @media screen and (min-width: 768px) {
                width: 80%;
            }
            th,
            td {
                text-align: left;
                display: block;
                padding-bottom:10px;
                @media screen and (min-width: 768px) {
                    display: table-cell;
                }
            }
        }
        .about-subtitle {
            transform: translate(0, 40px);
            @media screen and (min-width: 768px) {
                transform: translate(0, 50%);
            }
        }
    }
    &-text {
        font-size: 14px;
        line-height: 1.8;
        padding: 0 15px;
    }
    &-h4 {
        position: relative;
        font-size: 21px;
        padding: 40px 15px;
        @media screen and (min-width: 768px) {
            font-size: 31px;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 50%;
            background: #e9c93f;
        }
    }
}
