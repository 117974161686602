.contact{
    &-title{
        width:75%;
    }
    &-flex{
        width:100%;
        max-width:1100px;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        justify-content:space-between;
        margin:0 auto;
        @media screen and (min-width:768px){
            flex-direction:row;
        }
        &-text,&-progress{
            padding-top:50px;
            padding-left:15px;
            padding-right:15px;
        }
        &-text{
            width:100%;
            font-size:14px;
            line-height:2;
            text-align:center;
            @media screen and (min-width:768px){
                width:48%;
                text-align:left;
                }
        }
        &-progress{
            width:100%;
            text-align:center;
            @media screen and (min-width:768px){
                width:52%;
            }
        }
    }
    &-form,&-confirm{
        width:100%;
        max-width:1000px;
        margin:50px auto;
        @media screen and (min-width:768px){
            margin:100px auto;
        }
        &-table{
            width:100%;
            max-width:700px;
            margin-right:0;
            margin-left:auto;
        }
        &-th,&-td{
            padding:10px 5px 5px;
            display:block;
            @media screen and (min-width:768px){
                display:table-cell;
                padding:10px 5px;
            }
            &.button{
                text-align:center;
            }
        }
        &-th{
            width:100%;
            text-align:left;
            font-weight: normal;
            vertical-align: top;
            line-height:2;
            .required{
                color:#f00;
            }
            @media screen and(min-width:768px){
                width:25%;
            }
        }
        &-td{
            width:100%;
            text-align:left;
            @media screen and (min-width:768px){
                width:75%;
            }
        }
        .input-width{
            width:100%;
            padding:5px;
            font-size:16px;
            background:transparent;
            border:1px solid #2d2521;
        }
        .input-textarea{
            min-height:150px;
        }
    }
    &-button{
        text-align:center;
    }
    &-confirm{
        &-th{
            background:#eae6e4;
        }
        &-td{
            background:#fff;
        }
    }
    &-btn{
        &-back{
            padding:5px 10px;
            border:1px solid #2d2521;
            color:#2d2521;
            background: transparent;
            cursor: pointer;
            margin:15px;
            &:hover{
                background:rgba(#2d2521,0.5);
            }
            @media screen and (min-width:768px){
                margin-top:50px;
            }
        }
        &-submit{
            padding:5px 40px;
            border:1px solid #2d2521;
            color:#fff;
            background: #2d2521;
            cursor: pointer;
            margin:15px;
            &:hover{
                background:rgba(#2d2521,0.5);
            }
            @media screen and (min-width:768px){
                margin-top:50px;
            }
        }
    }
    &-thanks{
        text-align:center;
        margin-top:100px;
    }
}