.service {
    &-title{
        width:75%;
    }
    &-header,
    &-production,
    &-branding,
    &-marketing,
    &-operation {
        background:#fcfaf6;
        &.bg1{
            background-image: url("images/service/bg1.svg");
            background-repeat:no-repeat;
            background-position: top center;
            background-size:100%;
        }
        &.bg2{
            background-image: url("images/service/bg2.svg");
            background-repeat:no-repeat;
            background-position: top center;
            background-size:100%;
        }
        &-flex {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            padding: 0 15px 50px;
            @media screen and (min-width: 768px) {
                flex-direction: row;
                padding: 0 50px 50px;
                justify-content: space-between;
            }
            &.rv {
                @media screen and (min-width: 768px) {
                    flex-direction: row-reverse;
                }
            }
        }
        &-col {
            width: 100%;
            padding-bottom: 70px;
            &.bg {
                background: linear-gradient(
                    to right,
                    rgba(255, 255, 255, 0.57) 0%,
                    rgba(255, 255, 255, 0.57) 60%,
                    rgba(255, 255, 255, 0) 60.5%,
                    rgba(255, 255, 255, 0) 100%
                );
            }
            &.img {
                padding: 30px 0;
                display:flex;
                justify-content: center;
                img{
                    max-width:240px;
                    margin:0 auto;
                    @media screen and (min-width:768px){
                        max-width:100%;
                    }
                }
            }
            @media screen and (min-width: 768px) {
                width: 48%;
            }
            .col-flex {
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                img {
                    margin: 8px 0;
                }
            }
            .icon-flex {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 30px;
            }
            .icon-col {
                margin: 5px auto;
                width: 48%;
                background: #f7f3e9;
                border-radius: 15px;
                &.nbg {
                    background: transparent;
                }
            }
        }
    }
    &-header{
        background:none;
        margin-top:70px;
    }
    &-subtitle {
        padding-top: 30px;
        mix-blend-mode: multiply;
        img {
            height: 100px;
            max-height: 234px;
            @media screen and (min-width: 768px) {
                height: 23.4vw;
            }
        }
        &.rv {
            @media screen and (min-width: 768px) {
                text-align: right;
            }
        }
    }
    &-h4 {
        position: relative;
        padding-top: 40px;
        font-size: 21px;
        padding-left: 15px;
        margin-bottom: 15px;
        @media screen and (min-width: 768px) {
            font-size: 31px;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 5px;
            background: #e9c93f;
        }
    }
    &-text {
        font-size: 14px;
        line-height: 1.8;
        padding: 0 15px;
    }
    &-support {
        min-height:900px;
        width:100%;
        background:#f0e9d6;
        @media screen and (min-width:768px){
            min-height:950px;
        }
        @media screen and (min-width:1000px){
            min-height:1100px;
        }
        &-flex {
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 1000px) {
                flex-direction: row-reverse;
            }
        }
        &-col {
            width: 100%;
            padding-bottom: 100px;
            position: relative;
            @media screen and (min-width:768px){
                padding-bottom: 70px;
            }
            @media screen and (min-width: 1000px) {
                min-height:720px;
                width: 40%;
            }
            &.figure{
                @media screen and (min-width: 1000px) {
                                    min-width:600px;
                }
            }
            .blue{
                border:3px solid #46bee6;
                border-radius:50%;
                width:70px;
                height:70px;
                margin:0 auto;
                z-index:3;
                position:absolute;
                top:calc(50% + 70px);
                left:50%;
                transform:translate(-50%,-50%);
                @media screen and (min-width:768px){
                    top:calc(50% + 160px);
                    width:100px;
                    height:100px;    
                }
            @media screen and (min-width: 1000px) {
                    position:absolute;
                    top:calc(50% + 60px);
                    left:50%;
                    transform:translate(-50%,-50%);
                }
                &-left,&-right{
                    width:55px;
                    height:55px;
                    background:#46bee6;
                    color:#fff;
                    font-size:12px;
                    font-weight:bold;
                    border-radius:60px;
                    display:flex;
                    align-items:center;
                    justify-content: center;
                    position:absolute;
                    @media screen and (min-width:768px){
                        font-size:16px;
                        width:80px;
                        height:80px;
                        }
                }
                &-left{
                    transform:translate(-50%,-25%);
                    top:0;
                    left:0;
                }
                &-right{
                    bottom:0;
                    right:0;
                    transform:translate(50%,25%);
                }
            }
            .circle {
                position: absolute;
                width:360px;
                height:360px;
                top:120px;
                left:50%;
                transform: translate(-50%,-50%);
                @media screen and (min-width:768px){
                    transform: translate(-50%,-50%);
                    top:200px;
                    width: 470px;
                    height: 470px;
                    }
            @media screen and (min-width:1000px){
                position: absolute;
                width: 600px;
                height: 600px;
                z-index: 2;
                top:120px;
                left:auto;
                transform:none;
                }
                &-title {
                    border-bottom: 1px solid #837e7c;
                    text-align: center;
                    padding-bottom: 10px;
                    width: 100%;
                    margin-bottom: 10px;
                    font-size:13px;
                    @media screen and (min-width:1000px){
                        font-size:16px;
                    }
                    &-eng {
                        font-size: 12px;
                        display: block;
                    }
                }
                &-flex {
                    width: 100%;
                    height:100%;
                    position:absolute;
                    top:0;
                    left: 0;
                    background: rgba(255, 255, 255, 0.8);
                    border-radius: 50%;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                }
                &-col {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    &:nth-child(2){
                        display:none;
                        @media screen and (min-width:1000px){
                            display:flex;
                        }
                    }
                }
                &-top,&-left,&-right,&-bottom{
                    position:absolute;
                    @media screen and (min-width:1000px){
                        position:absolute;
                        margin:0;
                    }
                }
                &-top {
                    left: 50%;
                    top: 0;
                    width: 150px;
                    height: 150px;
                    transform: translate(-50%, 0);
                    @media screen and (min-width:768px){
                        width: 200px;
                        height: 200px;
                        }
                    @media screen and (min-width:1000px){
                        width: 280px;
                        height: 280px;
                        }
                }
                &-left {
                    left: 0;
                    top: 50%;
                    width: 150px;
                    height: 150px;
                    transform: translate(0, -50%);
                    @media screen and (min-width:768px){
                        width: 200px;
                        height: 200px;
                        }
                    @media screen and (min-width:1000px){
                        width: 260px;
                        height: 260px;
                        }
                }
                &-right {
                    right: 0;
                    top: 50%;
                    padding: 20px;
                    width: 150px;
                    height: 150px;
                    transform: translate(0, -50%);
                    @media screen and (min-width:768px){
                        width: 200px;
                        height: 200px;
                        }
                    @media screen and (min-width:1000px){
                        width: 260px;
                        height: 260px;
                        }
                }
                &-bottom {
                    left: 50%;
                    bottom: 0;
                    padding: 20px;
                    width: 150px;
                    height: 150px;
                    transform: translate(-50%, 0);
                    @media screen and (min-width:768px){
                        width: 200px;
                        height: 200px;
                        }
                    @media screen and (min-width:1000px){
                        width: 250px;
                        height: 250px;
                        }
                }
                .marketing {
                    &-flex {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                    }
                    &-col {
                    }
                }
            }
            .title {
                font-size: 11px;
                font-weight: bold;
            }
            .icon-text {
                font-size: 11px;
                letter-spacing: -1px;
                &::before {
                    content: "・";
                }
            }
        }
    }
}
