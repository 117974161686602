.mainvisual {
    overflow: hidden;
    width:100%;
    position:relative;
    &-catch{
        &-copy{
            font-weight:normal;
            font-size:18px;
            padding:10px;
            @media screen and (min-width:768px){
                font-size:32px;
                position:absolute;
                top:50px;
                left:0;
            }
            .blue{
                color:#46bee6;
            }
            .yellow{
                color:#e9c93f;
            }
        }
    }
    &-area {
        margin: 0 auto;
        transform:translate(-15vw,0);
        transform-origin: top center;
        height:100vw;
        width:130vw;

        @media screen and (min-width: 768px) {
            transform:none;
            height:auto;
            width: 100%;
            max-width: 1300px;
        }
    }
}
