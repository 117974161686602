.footer {
    padding: 40px 15px;
    background: #2c2421;
    &-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        }
    }
    &-menu {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        &-list{
            margin:5px;
            text-align:center;
        }
        &-btn{
            color:#fff;
            font-size:12px;
        }
        &-fb{
            margin:5px 30px;
        }
    }
}
