/* CSS Document */
@keyframes open {
    0% {
        transform: translateY(-150%);
    }
    50% {
    }
    100% {
        transform: translateY(0);
    }
}

header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    border-top: 15px solid #2c2421;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    .logo {
        padding: 1px;
        line-height: 0;
        position:relative;
        z-index:151;
        img {
            height: 50px;
        }
    }
    .menu {
        font-size: 1.5em;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }
    .mw {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: center;
        @media screen and (min-width: 768px) {
            height: 80px;
        }
    }
    position: fixed;
    z-index: 1000;
    #nav-drawer {
    }

    /*チェックボックス等は非表示に*/
    .nav-unshown {
        display: none;
    }

    /*アイコンのスペース*/
    #nav-open {
        position: fixed;
        top: 15px;
        right: 15px;
        display: flex;
        align-items: center;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        z-index: 1640;
        cursor: pointer;
        font-size: 15px;
        font-weight: 400;
        color: #35314c;
        &:before {
            content: "MENU";
            transform: translate(calc(-100% - 20px), 0);
            color: #2c2421;
            font-size: 14px;
            font-weight: bold;
        }
        @media screen and (min-width: 768px) {
            top: 30px;
        }
        @media screen and (min-width: 1200px) {
            right: calc((100% - 1200px) / 2 + 15px);
        }
        span {
            margin-top: 15px;
            position: absolute;
            top: 50%;
            transform: translateY(calc(-50% - 15px));
            right: 45px;
            width: 0;
        }
        span,
        span:before,
        span:after {
            position: absolute;
            z-index: 1660;
            height: 2px; /*線の太さ*/
            width: 40px; /*長さ*/
            border-radius: 15px;
            background: #2c2421;
            display: block;
            content: "";
            z-index: 200;
            left: 0;
            transition: all 0.25s ease-in-out;
        }
        &.scr {
            span:before,
            span:after {
                position: absolute;
                width: 40px; /*長さ*/
                border-radius: 15px;
                background: #2c2421;
                display: block;
                content: "";
                z-index: 200;
            }
        }
        span {
            bottom: 0;
        }
        span:before {
            bottom: 7px;
            margin-left: 7px;
            -webkit-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;
        }
        span:after {
            bottom: -7px;
            margin-left: -7px;
            -webkit-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;
        }
        img {
            position: absolute;
            bottom: 0;
            left: -2px;
            width: 36px;
            transform: translate(22px, -10px);
        }
    }
    .close-text {
        display: none;
        position: relative;
        z-index: 151;
    }
    .nav-active {
        #nav-open {
            &:before {
                content:"CLOSE";
                color: #2c2421;
            }
            span {
                background: transparent;
            }
            span:before,
            span:after {
                bottom: 0;
                background: #2c2421;
                margin-left: 0;
            }
            span:before {
                width: 40px;
                -webkit-transform: rotate(405deg);
                transform: rotate(405deg);
            }
            span:after {
                width: 40px;
                right: auto;
                -webkit-transform: rotate(-405deg);
                transform: rotate(-405deg);
            }
        }
        .close-text {
            display: none;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            padding: 4px;
            margin-right: 20px;
            margin-top: -15px;
        }
    }

    /*ハンバーガーアイコンをCSSだけで表現*/

    /*閉じる用の薄黒カバー*/
    #nav-close {
        display: none; /*はじめは隠しておく*/
        position: fixed;
        z-index: 99;
        top: 0; /*全体に広がるように*/
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0;
        transition: 0.3s ease-in-out;
    }

    /*中身*/
    #nav-bg {
        display: none;
        background: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100vh;
        position: fixed;
        z-index: 2;
    }
    #nav-content {
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 150; /*最前面に*/
        width: 100%;
        height: calc(100vh);
        transition: 0.5s ease-in-out;
        /*transform: translate(100%, 0);*/
        background: rgba(#fff, 1); /*背景色*/
        text-align: center;
        color: #2c2421;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
        /*max-width: 400px;
        @media screen and (min-width: 400px) {
            margin-left: calc(100% - 400px);
        }
        @media screen and (min-width:1200px){
            max-width:calc(100% - 1200px + 400px);
            margin-left: calc(100% - (400px + (100% - 1200px)));
        }*/
        clip-path: polygon(0 15px, 100% 15px, 100% 15px, 0 15px);
        .image {
            width: 100%;
            height: 40vh;
            background: #f7f3e9;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (min-width: 768px) {
                width: 50%;
                height: 100vh;
            }
        }
        .menu-category {
            display: flex;
            width: 100%;
            
            justify-content: center;
            padding: 80px 10px 10px;
            box-sizing: border-box;
            align-items:center;
            height:60vh;
            flex-direction:column;
            @media screen and (min-width: 768px) {
                flex-wrap: wrap;
                flex-direction: row;
                max-width: 400px;
                padding: 0 10px;
                height:auto;
            }
                &-block {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                color: #2c2421;
                height: 50px;
            }
            &-form {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                width: 80%;
                margin: 0 auto;
                margin-top: 30px;
            }
            &-fb{
                margin-top:5%;
                width:70%;
                display:flex;
                justify-content: flex-start;
                @media screen and (min-width:768px){
                }
            }
            &-title {
                text-align: center;
                position: relative;
                width: 80%;
                display: block;
                padding:10px;
                @media screen and (min-width:768px){
                    height: 100%;
                }
                .hoverimg{
                    position:absolute;
                    z-index:152;
                    opacity:0;
                    top:0;
                    left:0;
                    transition: all 0.3s ease-in-out;;
                    &:hover{
                        opacity:1;
                    }
                }
                &-link {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    align-items: center;
                    justify-content: flex-start;
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -1px;
                        width: 30%;
                        min-width: 90px;
                        height: 1px;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        width: 5px;
                        height: 8px;
                        transform: translate(0, -50%);
                        //background:url("../images/btn-1-arrow.svg")no-repeat center center;
                    }
                    .line {
                        color: #2c2421;
                        font-size: 16px;
                        font-weight: bold;
                        transition: 0.2s ease-in-out;
                        text-transform: uppercase;
                        position: relative;
                        overflow: hidden;
                        padding: 0 5px;
                        letter-spacing: 1px;
                        &:after {
                            position: absolute;
                            transition: 0.3s;
                            content: "";
                            width: 0;
                            left: 50%;
                            bottom: 0px;
                            height: 3px;
                            background: #35314c;
                            right: 0;
                            left: auto;
                        }
                    }
                    &:hover {
                        .line {
                            cursor: pointer;
                            &:after {
                                width: 120%;
                                left: -10%;
                                bottom: 0px;
                            }
                        }
                    }
                }
                &-btn {
                    display: block;
                    width: 100%;
                    margin: auto;
                    padding: 0;
                    text-align: left;
                    font-size: 16px;
                    box-sizing: border-box;
                    transition: 0.2s ease-in-out;
                    font-weight: 900;
                    position: relative;
                    overflow: hidden;
                    color: #2c2421;
                    &:after {
                        position: absolute;
                        transition: 0.3s;
                        content: "";
                        width: 0;
                        left: 50%;
                        bottom: 0px;
                        height: 100%;
                        right: 0;
                        left: auto;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0px;
                        width: 30%;
                        min-width: 90px;
                        height: 1px;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        width: 5px;
                        height: 8px;
                        transform: translate(0, -50%);
                        //background:url("../images/btn-1-arrow.svg")no-repeat center center;
                    }
                }
            }
            .lowbtn {
                &-ul {
                    display: none;
                }
            }
            &-li {
            }
            .jcfs {
                justify-content: flex-start;
            }
        }
        &-inquiry {
            width: 100%;
            position: relative;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px 0;
            &-btn {
                display: block;
                width: 100%;
                margin: auto;
                padding: 10px;
                text-align: left;
                color: #fff;
                font-size: 17px;
                box-sizing: border-box;
                transition: 0.2s ease-in-out;
                font-weight: 900;
                position: relative;
                overflow: hidden;
                padding-left: 30px;
                &:after {
                    position: absolute;
                    transition: 0.3s;
                    content: "";
                    width: 0;
                    left: 50%;
                    bottom: 0px;
                    height: 100%;
                    background: linear-gradient(to right, rgba(141, 211, 228, 1) 0%, rgba(53, 71, 151, 1) 100%);
                    right: 0;
                    left: auto;
                }
                border-radius: 5px;
                position: relative;
                background: linear-gradient(to right, rgba(141, 211, 228, 1) 0%, rgba(53, 71, 151, 1) 100%);
                justify-content: flex-start;
                color: #fff;
                display: flex;
                align-items: center;
                height: 100%;
                margin: 5px 0;
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    width: 5px;
                    height: 8px;
                    transform: translate(0, -50%);
                    //background:url("../images/btn-1-arrow.svg")no-repeat center center;
                }
            }
        }
        &-tel {
            width: 100%;
            position: relative;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px 0;
            &-btn {
                display: block;
                width: 100%;
                margin: auto;
                padding: 10px 0;
                text-align: left;
                color: #fff;
                font-size: 17px;
                box-sizing: border-box;
                transition: 0.2s ease-in-out;
                font-weight: 900;
                position: relative;
                overflow: hidden;
                padding-left: 30px;
                .text {
                    display: flex;
                    padding: 0 10px;
                }
                &:after {
                    position: absolute;
                    transition: 0.3s;
                    content: "";
                    width: 0;
                    left: 50%;
                    bottom: 0px;
                    height: 100%;
                    background: linear-gradient(to right, rgba(141, 211, 228, 1) 0%, rgba(53, 71, 151, 1) 100%);
                    right: 0;
                    left: auto;
                }
                border-radius: 5px;
                position: relative;
                background: linear-gradient(to right, rgba(141, 211, 228, 1) 0%, rgba(53, 71, 151, 1) 100%);
                justify-content: flex-start;
                color: #fff;
                display: flex;
                align-items: center;
                height: 100%;
                margin: 5px 0;
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    width: 5px;
                    height: 8px;
                    transform: translate(0, -50%);
                    //background:url("../images/btn-1-arrow.svg")no-repeat center center;
                }
            }
        }
    }

    /*チェックが入ったらもろもろ表示*/
    #nav-input:checked ~ #nav-close {
        display: block; /*カバーを表示*/
        opacity: 0;
    }

    #nav-input:checked ~ #nav-content {
        /*transform: translate(0, 0%);*/
        clip-path: polygon(0 15px, 100% 15px, 100% 100vh, 0 100vh);
    }
}
#top_message {
    padding-top: 90px;
}
.mt5 {
    margin-top: 50px;
}
